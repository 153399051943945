import React, { useEffect, useState } from 'react'
import { translate } from 'utils/utils'
import Range from 'components/Range'
import { useCustomizeHomePageContext } from 'pages/store/pages/customize-home-page/store/CustomizeHomePageContext'
import LocalApplyToAll from '../../components/LocalApplyToAll'
import DialogComponent from 'components/tags/Dialog'
import ColorPicker from 'components/ColorPicker'
import browserData from '../../../../../../../../json/browser-data.json'


const EditGeneralMarginTop=()=>{
  const {sectionDesign, selectedSectionId, updateSectionDesign, sections, visionMode} = useCustomizeHomePageContext()    
  const marginTop = sectionDesign.marginTop

  const setMarginTop=(value, toAll=false)=>{
    const newDesign={
      marginTop: Number(value)
    }
    updateSectionDesign(selectedSectionId, newDesign, toAll)
  }


  const applyToAllAction=()=>{
    const newDesign={
      marginTop
    }
    sections.forEach((section)=>{
      updateSectionDesign(section.id, newDesign)
    }, [])
     
  }

  return(
    <div>
      <div className='d-f align-items-center g-3'>
          <p>{ translate('Margin top') }:</p>
      </div>
      <Range
        min={0}
        max={56}
        step={2}
        value={marginTop}
        setValue={setMarginTop}
      />
      <LocalApplyToAll 
        action={applyToAllAction} 
        chain={[translate('Margin top')]}
        type={translate('Categories')}
      />
    </div>
  )
}

const EditGeneralMarginHorizontal=()=>{
  const {sectionDesign, selectedSectionId, updateSectionDesign, sections} = useCustomizeHomePageContext()    
  const marginHorizontal = sectionDesign.marginHorizontal

  const setMarginHorizontal=(value, toAll=false)=>{
    const newDesign={
      marginHorizontal: Number(value)
    }
    updateSectionDesign(selectedSectionId, newDesign, toAll)
  }


  const applyToAllAction=()=>{
    const newDesign={
      marginHorizontal: marginHorizontal
    }
    sections.forEach((section)=>{
      updateSectionDesign(section.id, newDesign)
    }, [])
     
  }

  return(
    <div>
      <div className='d-f align-items-center g-3'>
          <p>{ translate('Margin Horizontal') }:</p>
      </div>
      <Range
        min={0}
        max={56}
        step={2}
        value={marginHorizontal}
        setValue={setMarginHorizontal}
      />
      <LocalApplyToAll 
        action={applyToAllAction} 
        chain={[translate('Margin horizontal')]}
        type={translate('Categories')}
      />
    </div>
  )
}

const EditSectionBackground=()=>{
  const {sectionDesign, selectedSectionId, updateSectionDesign, visionMode, sections} = useCustomizeHomePageContext()    
  const backgroundColorObject =  sectionDesign.backgroundColor
  const backgroundColor = backgroundColorObject[visionMode]

  const setBackgroundColor=(value)=>{
    const newDesign={
      backgroundColor: {
        ...backgroundColorObject,
        [visionMode]: value
      }
    }
    updateSectionDesign(selectedSectionId, newDesign)
  }

  const applyToAllAction=()=>{
    const newDesign={
      backgroundColor: backgroundColorObject
    }
    sections.forEach((section)=>{
      updateSectionDesign(section.id, newDesign)
    }, [])
     
  }


  const [showColorPicker, setShowColorPicker] = useState(false)

  const  bgTransparent = backgroundColor === "#00000000"
  const colors = browserData['browserData']['colors'][visionMode]
  const onChnage = (e)=>{
    const checked = e.target.checked
    if(checked){
      setBackgroundColor('#00000000')
    } 
    else{
      setBackgroundColor(colors.backgroundColor)
    }
  }
  return(
    <div className='mt-3'>
      <div className='d-f align-items-center g-3 mb-3'>
          <input type='checkbox' checked={bgTransparent} onChange={onChnage} style={{zoom: '0.8'}} />
          <h4>{ translate('Transparent background') }</h4>
      </div>
      { !bgTransparent &&<div className='d-f align-items-center g-3'>
        <p>{ translate('Background color') }:</p>
        <h4 className='flex-1'>{ backgroundColor === '#00000000' ? translate('Transparent') : backgroundColor }</h4>
         <div 
          style={{
            border: '1px solid black', 
            padding: 1, 
            width: 54,
            borderRadius: 6
          }} 
          className='cursor-pointer'
          onClick={()=>setShowColorPicker(true)}
        >
          <div style={{
            backgroundColor: backgroundColor,
            width: 50,
            height: 20,
            borderRadius: 4,
          }} />
        </div>
      </div>
      }
      <DialogComponent open={showColorPicker} backDropPressCloses={false} close={ ()=>setShowColorPicker(false) }>
        <ColorPicker color={backgroundColor} onChange={setBackgroundColor} />
      </DialogComponent>
      <LocalApplyToAll
        chain={[
          translate('Background color'),
        ]} 
        type={translate('Categories')}
        action={applyToAllAction}
      />
    </div>
  )
}

export {EditGeneralMarginTop, EditGeneralMarginHorizontal, EditSectionBackground}