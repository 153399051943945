import { useCustomizeHomePageContext } from 'pages/store/pages/customize-home-page/store/CustomizeHomePageContext'
import React from 'react'


const SwiperImageCard=({imageObject, sectionDesign})=>{
    const {visionMode} = useCustomizeHomePageContext()
    
    const {
        gap,
        image: {
            border: {
                radius: borderRadius,
                color:  {
                    [visionMode] : borderColor
                },
            width: borderWidth,
            },
            aspectRatio,
            objectFit,
            width,
        }
    } = sectionDesign

    return(
        <div style={{padding: gap/2, width}}>
            <div  
                style={{ 
                    overflow: 'hidden', 
                    borderRadius: borderRadius, 
                    border: `${borderWidth}px solid ${borderColor}`,
                }}
            >
                    
                <div 
                    style={{
                        width: '100%', 
                        aspectRatio: aspectRatio, 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent:'center',
                        backgroundImage: `url(${imageObject.url})`,
                        backgroundSize: objectFit,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }} 
                />                        
            </div>
        </div>
            
)}

export default SwiperImageCard