import Button from 'components/Button'
import React, { useEffect, useState } from 'react'
import { useCustomizeHomePageContext } from '../store/CustomizeHomePageContext'
import { useDialogContext } from 'components/tags/Dialog'
import { translate } from 'utils/utils'
import axios from 'axios'
import { apiUrl } from 'constants/urls'
import { useBrowserContext } from 'store/browser-context'
const DeviceButton=({type, className, setSelectedDevice, selectedDevice})=>(<Button background outline={selectedDevice === type} onClick={()=>setSelectedDevice(type)} className='px-1'><i style={{fontSize: 24, width: 32}} className={className} /></Button>)

function extractImageUrls(data){
    let urls = [];

    // Loop through each object in the data array
    data.forEach(item => {
        // Check if the object contains imageObjects
        if (item.imageObjects && Array.isArray(item.imageObjects)) {
            // Extract URLs from imageObjects
            item.imageObjects.forEach(imageObject => {
                if (imageObject.url) {
                    urls.push(imageObject.url);
                }
            });
        }
    });

    return urls;
}

const DeviceTypeArea = () => {
    const {setSelectedDevice, selectedDevice, setFullScreen, fullScreen, setselectedSectionId, sections, generalDesignObject} = useCustomizeHomePageContext()
    useEffect(()=>{
      if (fullScreen) setselectedSectionId(null)
      else setselectedSectionId('general-design')
    }, [fullScreen])

    const {closeDialog} = useDialogContext()
    const {setGlobalMessageA} = useBrowserContext()
    const [saving, setSaving] = useState(false)
    const saveHomePage = async()=>{
      setSaving(true)
      try{
        await axios.post(
          apiUrl + '/store/update-homepage',
          {
            store_id : localStorage.getItem('storeId'),
            sections: sections,
            general_design: generalDesignObject,
            images: extractImageUrls(sections.filter(sec=>sec.type=== 'swiper'))
          },
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          }
        )
        setGlobalMessageA({
          children: translate('Home was updated successfully'),
          color: 'var(--successColor)',
          time: 3000
        })
      }catch(err){
        setGlobalMessageA({
          children: translate('Home was not updated'),
          color: 'red',
          time: 3000
        })
      }
      setSaving(false)
    }

    const {homePageMode} = useCustomizeHomePageContext()
  return (
    <div style={{position: 'absolute', top: 4, right: 6, zIndex: 2, width: 300,}}>
      
      <div className='d-f m-2'>
          <div className='d-f g-3 flex-1'>
            <Button background onClick={()=>{fullScreen ? closeDialog() : setFullScreen(true)}} >
              <i className={`fa-solid fa-${fullScreen ? 'compress' : 'expand'}`}></i>
            </Button>
            <DeviceButton type='mobile' setSelectedDevice={setSelectedDevice} selectedDevice={selectedDevice} className={'fa-solid fa-mobile-screen'} />    
            <DeviceButton type='PC' setSelectedDevice={setSelectedDevice} selectedDevice={selectedDevice} className={'fa-solid fa-laptop'} />    
          </div>
         { !fullScreen && !homePageMode && <Button background style={{height: 34}} className='g-3' onClick={saveHomePage} disabled={saving}>
            <i className='fa-solid fa-save' style={{ fontSize: 24 }} />
            { translate('Save') }
          </Button>}
      </div>
    </div>
      
  )
}

export default DeviceTypeArea