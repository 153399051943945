import axios from "axios"
import { apiUrl } from "constants/urls"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useBrowserContext } from "store/browser-context"
import { useUserContext } from "store/user-context"
import { translate } from "utils/utils"

const useLogout = () => {
    const {userData, setUserData} = useUserContext()
    const {setGlobalMessageA} = useBrowserContext()
  
    const navigate = useNavigate()

  
    const [loading, setloading]=useState(false)
    const logout=async()=>{
        setloading(true)
        try{
          await axios.post(
            apiUrl + "/user/logout", 
            {
              token: userData.token
            },
            {
                headers : {
                  "Content-Type": "application/json",
                  Authorization : 'Bearer ' + userData.token
                }
            }
          )
          setGlobalMessageA({
            color: 'var(--successColor)',
            time: 3000,
            children: translate('You are logged out')
          })
          setUserData(null)
          navigate('/login/')
          setloading(false)
          return true
        }catch(error){
          console.error(error)
          setGlobalMessageA({
            color: 'red',
            time: 3000,
            children: translate('Logout Failed')
          })
          setloading(false)
          return false
        }
        
    }
    return {logout, loading}
}

export default useLogout